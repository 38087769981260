'use client';

import React from 'react';
import Image from 'next/image';
import { Button } from '@gravity/button';
import { Heading } from '@gravity/heading';
import { useTranslations } from 'next-intl';
import { AuthEvents, AuthPageNames } from '@monorepo/events/src/types';
import { AppBar } from '@monorepo/tailwind-ui/src/components/AppBar';
import { cn } from '@monorepo/tailwind-ui/utils';
import SignInImage from '@src/assets/login.jpeg';
import MeuIsaacLogo from '@src/assets/logo_meu_isaac_entrada.svg';
import { useSendButtonClickEvent } from '@src/hooks/useSendButtonClickEvent';
type Props = {
  showButton?: boolean;
};
export const InitialLogin = ({
  showButton = true
}: Props) => {
  const t = useTranslations('signInPage');
  const {
    sendButtonClickEvent
  } = useSendButtonClickEvent();
  const isAlreadyVisited = () => {
    if (typeof window === 'undefined') {
      return false;
    }
    return sessionStorage?.getItem('showFirstImage') === 'true';
  };
  const [showImage, setShowImage] = React.useState(isAlreadyVisited());
  return <>
      {showButton ? <AppBar className="md:hidden" onClick={() => {
      sessionStorage.setItem('showFirstImage', 'false');
      setShowImage(true);
    }} /> : null}
      <div id="InitialLogin" className={cn('fixed top-0 z-10 flex h-full w-full flex-1 bg-blue-400 transition-transform duration-300 md:relative md:translate-x-0', !showImage && '-translate-x-full', !showButton && 'hidden md:block')}>
        <Image src={SignInImage} alt="" className="h-full w-full object-cover object-[75%]" fill={true} data-sentry-element="Image" data-sentry-source-file="index.tsx" />
        <div className="absolute inset-0 bg-gradient-to-b from-black/0 from-[-41.48%] to-black/70" />
        <div className="absolute inset-x-0 bottom-6 m-6 flex md:bottom-10">
          <div className="max-w-lg">
            <Image src={MeuIsaacLogo.src} alt="Logo Meu Isaac" height={18} width={108} className="mb-6" data-sentry-element="Image" data-sentry-source-file="index.tsx" />
            <Heading variant="heading-h3-medium" className="text-white" data-sentry-element="Heading" data-sentry-source-file="index.tsx">
              {t('meuIsaacDescription')}
            </Heading>
            {showButton ? <Button className="mt-12 md:hidden" fullWidth onClick={() => {
            sendButtonClickEvent({
              pageName: AuthPageNames.LOGIN,
              identifierName: AuthEvents.CLICK_INITIAL_LOGIN,
              customData: {
                button_name: 'Começar'
              }
            });
            sessionStorage.setItem('showFirstImage', 'true');
            setShowImage(false);
          }}>
                {t('start')}
              </Button> : null}
          </div>
        </div>
      </div>
    </>;
};